import axios from "@/axios/axiosConfig";

export const pageCustomer = (params) => {
  return axios({
    url: "/customer/pageCustomer",
    method: "post",
    data: params
  });
};

export const getCustomer = (params) => {
  return axios({
    url: `/customer/getCustomer/${params.customerId}`,
    method: "get"
  });
};

export const queryCustomerCount = () => {
  return axios({
    url: "/customer/queryCustomerCount",
    method: "post"
  });
};

export const addCustomer = (params) => {
  return axios({
    url: "/customer/addCustomer",
    method: "post",
    data: params
  });
};

export const updateCustomer = (params) => {
  return axios({
    url: "/customer/updateCustomer",
    method: "post",
    data: params
  });
};

export const deleteCustomer = (params) => {
  return axios({
    url: `/customer/deleteCustomer/${params.customerId}`,
    method: "get"
  });
};

export const getOrgNatureJson = () => {
  return axios({
    url: "/common/getOrgNatureJson",
    method: "get"
  });
};

export const listHardware = (params) => {
  return axios({
    url: `/hardware/listHardware/${params.customerId}/${params.hardwareType}`,
    method: "post"
  });
};

export const getHardware = (params) => {
  return axios({
    url: `/hardware/getHardware/${params.hardwareId}`,
    method: "get"
  });
};

export const deleteHardware = (params) => {
  return axios({
    url: `/hardware/deleteHardware/${params.hardwareId}`,
    method: "get"
  });
};

export const addHardware = (params) => {
  return axios({
    url: "/hardware/addHardware",
    method: "post",
    data: params
  });
};

export const updateHardware = (params) => {
  return axios({
    url: "/hardware/updateHardware",
    method: "post",
    data: params
  });
};

export const addStaff = (params) => {
  return axios({
    url: "/staff/addStaff",
    method: "post",
    data: params
  });
};

export const getStaff = (params) => {
  return axios({
    url: `/staff/getStaff/${params.staffId}`,
    method: "get"
  });
};

export const updateStaff = (params) => {
  return axios({
    url: "/staff/updateStaff",
    method: "post",
    data: params
  });
};

export const deleteStaff = (params) => {
  return axios({
    url: `/staff/deleteStaff/${params.staffId}`,
    method: "get"
  });
};

export const listMedicine = (params) => {
  return axios({
    url: `/medicine/listMedicine/${params.customerId}`,
    method: "get"
  });
};

export const addMedicine = (params) => {
  return axios({
    url: "/medicine/addMedicine",
    method: "post",
    data: params
  });
};

export const getMedicine = (params) => {
  return axios({
    url: `/medicine/getMedicine/${params.medicineId}`,
    method: "get"
  });
};

export const deleteMedicine = (params) => {
  return axios({
    url: `/medicine/deleteMedicine/${params.medicineId}`,
    method: "get"
  });
};

export const updateMedicine = (params) => {
  return axios({
    url: "/medicine/updateMedicine",
    method: "post",
    data: params
  });
};

export const tianyanchaSearch = (params) => {
  return axios({
    url: "/common/tianyancha-search",
    method: "post",
    data: params
  });
};
