<template>
  <div class="form-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-form-box",
  data () {
    return {
    };
  },
  created () { },
  methods: {},
  components: {}
};
</script>

<style scoped>
.form-box {
  margin: auto 0.15rem;
}
</style>
