<template>
  <div class="app-box">
    <x-scroll-view>
      <x-form-box>
        <x-form-label v-if="xhrData.medicineName" label="药品名称" disable :bindValue="xhrData.medicineName"></x-form-label>
        <x-form-input v-if="xhrData.otherMedicineName" label="新建药品名称" disable v-model="xhrData.otherMedicineName"></x-form-input>
        <x-form-input v-if="xhrData.commonName" label="通用名" disable v-model="xhrData.commonName"></x-form-input>
        <x-form-input v-if="xhrData.specification" label="规格" disable v-model="xhrData.specification"></x-form-input>
        <x-form-input v-if="xhrData.unit" label="单位" disable v-model="xhrData.unit"></x-form-input>
        <x-form-input v-if="xhrData.manufacturer" label="生产厂家" disable v-model="xhrData.manufacturer"></x-form-input>
        <x-form-select v-show="xhrData.purchasingChannelType!=null" label="采购渠道" disable :slots="PurchasingChannelEnum" :bindValue="xhrData.purchasingChannelType"></x-form-select>
        <x-form-input v-if="xhrData.otherPurchasingChannel" label="新建采购渠道" disable v-model="xhrData.otherPurchasingChannel"></x-form-input>
        <x-form-input v-if="xhrData.monthlyUsageAmount&&customerType==1000" label="月使用量" disable v-model="xhrData.monthlyUsageAmount"></x-form-input>
        <x-form-input v-if="xhrData.monthlySales&&customerType==2000" label="月销量" disable v-model="xhrData.monthlySales"></x-form-input>
      </x-form-box>
      <i v-if="!isLeader" class="edit-icon" @click="editEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formLabel from "@/components/formControl/formLabel";
import scrollView from "@/components/scrollView";
import { getMedicine } from "@/api/customerCenter";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      xhrData: {},
      PurchasingChannelEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  created () {
    this.setEnum();
  },
  methods: {
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).PurchasingChannelEnum;
      Object.keys(configJson).forEach(key => {
        this.PurchasingChannelEnum[0].values.push({
          code: key,
          name: configJson[key]
        });
      });
      this.getData();
    },
    getData () {
      getMedicine({
        medicineId: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = "";
        document.title = this.xhrData.medicineName;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/customerCenter/drugsInfo/edit/edit/${this.customerType}/${this.$route.params.id}`
      });
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formLabel.name]: formLabel,
    [scrollView.name]: scrollView
  }
};
</script>

<style scoped></style>
