<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td @click="selectEvent">
            <p class="select-txt" :class="{'select-txt-placeholder':!checkItem,'select-txt-placeholder-disable':disable}">{{checkItem?checkItem.name:"请选择"}}</p>
          </td>
        </tr>
      </table>
    </div>
    <i class="select-icon"></i>
    <mt-popup v-model="popupShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupShow=false;">取消</button>
          <button @click="finishEvent">完成</button>
        </div>
        <mt-picker :slots="slots" value-key="name" @change="onValuesChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-form-select",
  data () {
    return {
      popupShow: false,
      checkItemTemp: null,
      checkItem: null
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    slots: {
      type: Array,
      default: () => []
    },
    disable: {
      type: Boolean,
      default: false
    },
    bindValue: {
      type: [Number, String],
      default: null
    }
  },
  created () { },
  methods: {
    onValuesChange (picker, values) {
      this.checkItemTemp = values;
    },
    finishEvent () {
      this.popupShow = false;
      this.checkItem = this.checkItemTemp[0];
      this.$emit("change", this.checkItem);
    },
    selectEvent () {
      if (!this.disable) {
        this.popupShow = !this.popupShow;
      }
    }
  },
  watch: {
    bindValue (newVal) {
      this.slots[0].values.map((item, index) => {
        if (newVal == item.code) {
          this.checkItemTemp = [item];
          this.checkItem = item;
          this.slots[0].defaultIndex = index;
        }
      });
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
.select-txt {
  font-size: 0.14rem;
  text-align: right;
  color: #30353d;
}
.select-txt-placeholder-disable {
  color: #737885;
}
.select-txt-placeholder {
  color: #afb2b9;
}
.select-icon {
  margin-left: 0.1rem;
  width: 0.07rem;
  height: 0.12rem;
  background: url('~@images/select_icon.png') no-repeat center/0.07rem 0.12rem;
}
</style>
