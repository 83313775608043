<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td>
            <input type="text" class="form-input" :disabled="disable" :placeholder="placeholder" :maxlength="maxlength" v-model="oldValue" @input="inputEvent">
          </td>
        </tr>
      </table>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-form-input",
  data () {
    return {
      oldValue: null
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    maxlength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    this.oldValue = this.value;
  },
  methods: {
    inputEvent (e) {
      switch (this.type) {
        case "number":
          this.oldValue = e.target.value.trim().replace(/[^\d]/g, "");
          break;
        case "numberDot":
          this.oldValue = e.target.value.trim().replace(/[^\d^/.]+/g, "");
          break;
        case "code":
          this.oldValue = e.target.value.trim().replace(/[^0-9A-Z]/g, "");
          break;
        default:
          break;
      }
      this.$emit("input", this.oldValue);
    }
  },
  watch: {
    value (val) {
      this.oldValue = val;
    }
  },
  components: {}
};
</script>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-input {
  width: 100%;
  text-align: right;
  border: 0;
  font-size: 0.14rem;
  color: #30353d;
}
.form-input::-webkit-input-placeholder {
  color: #afb2b9;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
</style>
